exports.ADD_TO_CART = 'ADD_TO_CART'
exports.ADD_ONE_TO_CART = 'ADD_ONE_TO_CART'
exports.CLOSE_CART = 'CLOSE_CART'
exports.DELETE_FROM_CART = 'DELETE_FROM_CART'
exports.OPEN_CART = 'OPEN_CART'
exports.REMOVE_ONE_FROM_CART = 'REMOVE_ONE_FROM_CART'
exports.REMOVE_ALL_FROM_CART = 'REMOVE_ALL_FROM_CART'
exports.SET_CAMPAIGN_CONFIG = 'SET_CAMPAIGN_CONFIG'
exports.SET_CART = 'SET_CART'
exports.SET_LAST_CATEGORIES = 'SET_LAST_CATEGORIES'
exports.SET_LAST_PAGE = 'SET_LAST_PAGE'
exports.SET_LAST_SKU = 'SET_LAST_SKU'
exports.SET_LAST_BREADCRUMBS = 'SET_LAST_BREADCRUMBS'
exports.SET_MONDAY_DISCOUNT = 'SET_MONDAY_DISCOUNT'
exports.SET_PICKUP_STORE = 'SET_PICKUP_STORE'
exports.SET_SUPERMARKET_ITEMS = 'SET_SUPERMARKET_ITEMS'
exports.SET_WINDOW_SIZE = 'SET_WINDOW_SIZE'
exports.SET_HOME_PAGE_CONFIG = 'SET_HOME_PAGE_CONFIG'
exports.SET_CATEGORY_PAGE_CONFIG = 'SET_CATEGORY_PAGE_CONFIG'
exports.SET_CATEGORIES_MENU = 'SET_CATEGORIES_MENU'
exports.SET_SELECTED_WALSTORE_STORE = 'SET_SELECTED_WALSTORE_STORE'
exports.SET_SHOPPING_CART = 'SET_SHOPPING_CART'
exports.CHECKOUT_REDIRECT = 'CHECKOUT_REDIRECT'
exports.UPDATE_PROMOTIONS = 'UPDATE_PROMOTIONS'
exports.INCREASE_PROMOENGINE_RETRIES = 'INCREASE_PROMOENGINE_RETRIES'
exports.RESET_PROMOENGINE_RETRIES = 'RESET_PROMOENGINE_RETRIES'
exports.SET_IS_LOADING_PRICES = 'SET_IS_LOADING_PRICES'
exports.SET_BANNER_SLOT_INFO = 'SET_BANNER_SLOT_INFO'
exports.UPDATE_AUTH = 'UPDATE_AUTH'
