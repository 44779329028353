const constants = {
  timeout: {
    FIVE_SECONDS: 5000,
    TEN_SECONDS: 10000,
    TWENTY_SECONDS: 20000,
    EIGHT_SECONDS: 8000,
  },
  SORT_BY_OPTIONS: {
    DESTACADOS: '',
    MENOR_PRECIO: 'price_asc',
    MAYOR_PRECIO: 'price_desc',
  },
  journeyStep: {
    CHECKOUT: 'checkout',
    STORAGE_SUFFIX: 'JourneyStep',
  },
  tenant: {
    CATEX: 'catalogo',
    SOD: 'supermercado',
    PUNTO_DE_COMPRA: 'puntodecompra',
  },
  searchPlaceholder: {
    CATEX_TEXT: 'Busca en Mundo Lider',
    SOD_TEXT: 'Busca en Supermercado',
    PDC_TEXT: 'Busca en Punto de Compra',
  },
  stores: {
    DEFAULT_STORE_ID: 57,
    DEFAULT_IMS_STORE_ID_CATEX: [6020, 6014],
    STORAGE_KEY_STORE: 'store',
  },
  delivery: {
    PICKUP_TYPE: 'S2S',
    HOME_DELIVERY_TYPE: 'HomeDelivery',
    STORAGE_KEY_DISPATCH: 'dispatchTypeV3',
    STORAGE_KEY_ADDRESS: 'deliveryAddressV3',
    STORAGE_SLOT_INFO: 'slotInfo',
    PREFIX_LOCAL_ADDRESSES_LIST: 'local-address-',
    STORAGE_KEY_IS_SELECTED_ADDRESS_GONNA_BE_SHOWN: 'isSelectedAddressGonnaBeShown',
    autocomplete: {
      MIN_LENGTH_AUTOCOMPLETE: 5,
      MS_SUGGESTION_DEBOUNCE: 400,
    },
    COMMUNE_NOT_FOUND: 'COMMUNE_NOT_FOUND',
    ADDRESS_COUNT_LIMIT: 'ADDRESS_COUNT_LIMIT',
  },
  channel: {
    SOD: 'SOD',
    BUYSMART: 'BuySmart',
    WALSTORE: 'WalStore',
  },
  STORAGE_EVENT: 'storage',
  clients: {
    COMMUNICATION_ERROR: 'COMMUNICATION_ERROR',
  },
  MIN_PRICE_AVAILABLE_TO_SELL: 1,
  MINIMUM_DISCOUNT_PERCENTAGE: 0,
  SITE_TO_STORE_MIN_PRICE: 1,
  MIN_QUANTITY_REMAINING_TO_COMPLETE_PACK: 1,
  PASSWORD_MIN_LENGTH: 5,
  PASSWORD_MAX_LENGTH: 20,
  PASSWORD_MIN_SCORE_ACCEPTED: 3,
  HTTP_CODES: {
    CODE_200: 200,
    CODE_401: 401,
    CODE_403: 403,
    CODE_404: 404,
    CODE_409: 409,
    CODE_500: 500,
  },
  profile: {
    PROFILE: 'profile',
    FIRST_NAME: 'firstName',
    WALMART_ID: 'walmartId',
    EMAIL: 'email',
  },
  maxQuantity: {
    UNLIMITED: -1,
  },
  STATIC_PATH: '/catalogo/images/',
  BS_SOD_SESSION_TOKEN: 'smArtP_e',
  WEEK_DAYS: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
  FILTERS: {
    BASE_CATEGORY_FILTERS: ['Promociones', 'Vendido por', 'Producto', 'Marca', 'Precio'],
    UNSORTABLE_FILTER: ['price.Precio'],
    PRICE: 'price.Precio',
    MAX_CATEX_FILTERS: 10,
  },
  DEFAULT_STREET_NUMBER: '0',
  PRODUCT_WITHOUT_IMAGE: '/catalogo/images/catalogo_no_photo.webp',
  LIQUID_PIXEL_REDIRECT_HOST: 'images.lider.cl',
  WALMART_IMAGE_HOST: 'i5.walmartimages.com',
  HITS_PER_PAGE: {
    MIN: 16,
    MAX: 100,
  },
  ADVISOR_VIEW_EVENT_DELAY: 2000,
  ADVISOR_VIEW_URL_EVENT: 'viewUrl',
  ADVISOR_CLICK_URL_EVENT: 'clickUrl',
  ADVISOR_IMPRESSION_URL_EVENT: 'impUrl',
  CHECKOUT_MESSAGE_ERROR: {
    PRODUCT_ID_NOT_EXIST: 'Error creating order: Given Product IDs do not exist',
    NON_EXISTENT_SKU: 'Intention with non existent sku',
    ERROR_NO_REDIRECT_CHECKOUT_URL: 'No redirect checkout URL',
    ERROR_SKU_WITHOUT_STOCK: 'SKU without stock',
    ERROR_PRODUCTS_WITHOUT_STOCK: 'Products without stock',
    ERROR_PRODUCTS_WITHOUT_QUANTITY_MATCH: 'Product response quantity does not match with requested quantity',
    ERROR_PRODUCTS_WITHOUT_VENDOR: 'Products without Vendor ID',
    ERROR_PRODUCTS_IMS_STOCK: 'Failed to get inventory from IMS',
  },
  HEADERS: {
    RECOMMENDER_ANALYTICS_ID: 'x-recommendation-analytics-id',
  },
  LOGIN_ERROR_RESPONSES: {
    USER_DOES_NOT_EXIST: 'USER_DOES_NOT_EXIST',
    USER_IS_LOCKED: 'USER_IS_LOCKED',
    USER_ID_OR_PASSWORD_IS_INCORRECT: 'USER_ID_OR_PASSWORD_IS_INCORRECT',
    EMAIL_UNCONFIRMED_BY_CUSTOMER: 'EMAIL_UNCONFIRMED_BY_CUSTOMER',
    INVALID_CREDENTIALS: 'INVALID_CREDENTIALS',
    BLOCKED_ACCOUNT: 'BLOCKED_ACCOUNT',
    GENERIC_ERROR: 'GENERIC_ERROR',
    INVALID_CAPTCHA: 'INVALID_CAPTCHA',
    USER_NOT_FOUND: 'USER_NOT_FOUND',
    CUSTOMER_DOES_NOT_EXIST: 'CUSTOMER_DOES_NOT_EXIST',
    CUSTOMER_NOT_FOUND: 'CUSTOMER_NOT_FOUND',
  },
  COMPLETE_ACCOUNT_OPTIONS: {
    CHECK_SKIP: 0,
    CHECK_REQUIRED: 1,
    CHECK_ALL: 2,
  },
  INVALID_RECAPTCHA_ERROR_NAME: 'INVALID_RECAPTCHA',
  APP_ID: 'BuySmart',
  CONFIRMATION_ERROR_IMAGE: '/catalogo/images/error_confirm.svg',
  LIMIT_SECONDS_BEFORE_RETRY_EMAIL_DELIVERY: 30,
  LIDER_PREHOME: 'https://www.lider.cl',
  LIDER_APP_HOME: 'https://app.lider.cl/links/prehome',
  COORDINATES_SOURCE: {
    GOOGLE: 'Google',
    HURRICANE: 'Hurricane',
  },
  DELIVERY_TAG_KEYS: {
    SITE_TO_STORE: 'site-to-store',
    FREE_SHIPPING: 'free-shipping',
  },
  SELLER: {
    MAX_LENGTH: 30,
  },
  FOOTER: {
    WHATSAPP_SELF_SERVICE_LINK: 'https://wa.me/56957211492?text=Hola,%20tengo%20una%20consulta',
  },
  INFO_HEADER_CATEX: {
    TEXTS: {
      SELL_WITH_US: 'Vende con nosotros',
      BCI_LIDER_CARD: 'Tarjeta Lider Bci',
      HELP_CENTER: 'Centro de ayuda',
    },
    LINKS: {
      SELL_WITH_US_LINK: 'https://marketplace.walmart.cl/s/',
      BCI_LIDER_CARD_LINK:
        'https://tarjetadigital.liderbciserviciosfinancieros.cl/ingreso-de-datos?utm_source=Lidercl&utm_medium=lidercl&utm_campaign=Refer',
      HELP_CENTER_LINK: 'https://www.lider.cl/servicio-al-cliente/',
    },
  },
  CONTINGENCY_ALERT: {
    COOKIE_NAME: 'alert-info',
    COOKIE_VALUE: 'limitExpire',
    COOKIE_EXPIRATION_HOURS: 1,
  },
  INFO_PAGE: {
    FEED_BACKALLY_INTELLECTUAL_PROPERTY: 'https://feedbackally.walmart.com/survey/2w6XX1xqZcepzgF/',
  },
  HOME_REPLENISHMENT: {
    ORIGIN_NAME: 'homereplenishment',
    PARAM: {
      ORIGIN: 'cartOrigin',
      DISABLED_LIDERAPP_SCREEM: 'cartOrigin',
      ADD_SKUS_TO_CART: 'addSkusToCart',
    },
  },
  LOCAL_STORAGE: {
    GR_SHOPPING_CART: 'supermercado_shoppingCart',
  },
  DEFAULT_STORE_ID: '57',
  VALID_CONTENT_TYPE_IMAGE_HEADERS: ['image/jpeg', 'image/webp', 'image/avif'],
}

export default constants
