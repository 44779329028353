import { getJSONItem, saveItemAsJSON } from '../../Helpers/localStorageHelper'
import constants from '../../common/constants'
import getSkusAvailabilityAndPrice from '../../common/clients/getSkusAvailabilityAndPrice'
import { searchParamValueFromLink } from '../../Helpers'
import ClientAPI from '../../common/ClientAPI'

const updateShoppingCartItems = (skus, shoppingCartItems) =>
  shoppingCartItems.map((item) => ({
    ...item,
    ...skus[item.sku],
  }))

const updateCartItems = async (shoppingCartItems, storeId) => {
  let updatedShoppingCartItems

  if (shoppingCartItems && shoppingCartItems.length > 0) {
    const skus = await getSkusAvailabilityAndPrice(shoppingCartItems, storeId)
    if (skus) {
      updatedShoppingCartItems = updateShoppingCartItems(skus, shoppingCartItems)
    }
  }

  return updatedShoppingCartItems
}

const getCartItemsFromLocalStorage = (shoppingCartName) => {
  const shoppingCartLS = getJSONItem(shoppingCartName)
  return shoppingCartLS === undefined ? [] : shoppingCartLS
}

const MAX_QUANTITY_DISPLAY_ITEMS = 100

const getCartQuantity = (cartItems) => {
  if (cartItems && cartItems.length > 0) {
    const cartQuantity = cartItems.reduce((acc, item) => acc + item.quantity, 0)
    return cartQuantity < MAX_QUANTITY_DISPLAY_ITEMS ? cartQuantity : '+99'
  }
  return 0
}

const getSoldOutProducts = (cartItems) =>
  cartItems.filter((item) => !item.available || item.quantity > item.maxAvailable)

const getStoreFromLocalStorage = () => getJSONItem(constants.stores.STORAGE_KEY_STORE)

const setStoreInLocalStorage = (store) => saveItemAsJSON(constants.stores.STORAGE_KEY_STORE, store)

const saveCartInStorage = (shoppingCartName, shoppingCartItems) => saveItemAsJSON(shoppingCartName, shoppingCartItems)

const addIndexPropertyFromOne = (array) => array.map((item, index) => ({ ...item, listIndex: index + 1 }))

export const isHomeReplenishmentByUrl = (url) => {
  if (window.__ENV__?.enableHomeReplenishment) {
    const { PARAM, ORIGIN_NAME } = constants.HOME_REPLENISHMENT
    return searchParamValueFromLink(url, PARAM.ORIGIN) === ORIGIN_NAME
  }
  return false
}

export const getSkusToAddToCartForHomeReplenishment = (url) => {
  let items = []
  if (isHomeReplenishmentByUrl(url)) {
    const { PARAM } = constants.HOME_REPLENISHMENT
    const skuParams = searchParamValueFromLink(url, PARAM.ADD_SKUS_TO_CART) || []
    items = skuParams?.split(',').map((skuAndQuantity) => {
      const [sku, quantity] = skuAndQuantity.split(':')
      return {
        sku: sku.trim(),
        quantity: parseInt(quantity) || 1,
      }
    })
  }
  return items
}

export const getAndMergeCart = (items) => {
  const storedShoppingCartItems = getJSONItem(constants.LOCAL_STORAGE.GR_SHOPPING_CART, [])
  if (storedShoppingCartItems?.length) {
    const mergedCart = []

    items.concat(storedShoppingCartItems).forEach((item) => {
      if (!mergedCart[item.sku]) {
        mergedCart[item.sku] = { sku: item.sku, quantity: item.quantity }
      } else {
        const quantity = Math.max(mergedCart[item.sku].quantity, item.quantity)
        mergedCart[item.sku].quantity = quantity
      }
    })
    return Object.values(mergedCart)
  }
  return items
}

export const updateItemsInformation = async (items, storeId) => {
  const clientAPI = new ClientAPI()
  const skuList = items.map((item) => item.sku)

  const updatedItemsInformation = (await clientAPI.getProductSelections(skuList, storeId)) || []
  if (updatedItemsInformation?.length > 0) {
    updatedItemsInformation.map((updatedItem) => {
      const item = items.find((item) => item.sku === updatedItem.sku)
      updatedItem.quantity = item.quantity
    })
  }

  return updatedItemsInformation
}

export {
  getCartItemsFromLocalStorage,
  getCartQuantity,
  getStoreFromLocalStorage,
  setStoreInLocalStorage,
  updateCartItems,
  getSoldOutProducts,
  saveCartInStorage,
  addIndexPropertyFromOne,
}
